.cursor-pointer {
  cursor: pointer;
}

.scrollable {
  overflow-y: scroll;
  /* max-height: 950px; */
  background: white;
  padding-top: 10px;
  padding-bottom: 10px;
}

form button {
  background: var(--custom-btn-bg-hover-color);
  border: none;
  color: var(--white-color);
}

.translate-y-50 {
  transform: translateY(-50%);
}
.text-primary {
  color: var(--primary-color) !important;
}
.btn-primary {
  background: var(--primary-color) !important;
  color: white;
  border: none;
}
.btn-primary:hover {
  background-color: #14542b !important;
}

td > small {
  color: black !important;
}

.avatar.avatar-xl {
  border-radius: 50%;
  background-color: #ececec;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.avatar.avatar-sm {
  border-radius: 50%;
  background-color: #ececec;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: small;
}

.avatar.avatar-2xl {
  border-radius: 50%;
  background-color: #ececec;
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
    font-size: large;
}

.pt-8{
  padding-top: 8rem !important;
}


.pt-md-10{
  @media (min-width: 768px) {
    padding-top: 10rem !important;
  }
}

.pt-lg-12{
  @media (min-width: 992px) {
    padding-top: 12rem !important;
  }

}

/* cyrillic-ext */
@font-face {
  font-family: '__Unbounded_808ccb';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/42d0cc3547aa4d1a-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Unbounded_808ccb';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/3c067b172afdf4a3-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Unbounded_808ccb';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/a242b0367c255886-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Unbounded_808ccb';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/e76f97d7c73ea077-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Unbounded_808ccb';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url(/_next/static/media/aa8d273d9caf0df4-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Unbounded_808ccb';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/42d0cc3547aa4d1a-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Unbounded_808ccb';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/3c067b172afdf4a3-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Unbounded_808ccb';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/a242b0367c255886-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Unbounded_808ccb';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/e76f97d7c73ea077-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Unbounded_808ccb';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/aa8d273d9caf0df4-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* cyrillic-ext */
@font-face {
  font-family: '__Unbounded_808ccb';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/42d0cc3547aa4d1a-s.woff2) format('woff2');
  unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
  font-family: '__Unbounded_808ccb';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/3c067b172afdf4a3-s.woff2) format('woff2');
  unicode-range: U+0301, U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* vietnamese */
@font-face {
  font-family: '__Unbounded_808ccb';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/a242b0367c255886-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: '__Unbounded_808ccb';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/e76f97d7c73ea077-s.woff2) format('woff2');
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Unbounded_808ccb';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/aa8d273d9caf0df4-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Unbounded_Fallback_808ccb';src: local("Arial");ascent-override: 73.93%;descent-override: 18.20%;line-gap-override: 0.00%;size-adjust: 134.59%
}.__className_808ccb {font-family: '__Unbounded_808ccb', '__Unbounded_Fallback_808ccb';font-style: normal
}

