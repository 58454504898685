.cursor-pointer {
  cursor: pointer;
}

.scrollable {
  overflow-y: scroll;
  /* max-height: 950px; */
  background: white;
  padding-top: 10px;
  padding-bottom: 10px;
}

form button {
  background: var(--custom-btn-bg-hover-color);
  border: none;
  color: var(--white-color);
}

.translate-y-50 {
  transform: translateY(-50%);
}
.text-primary {
  color: var(--primary-color) !important;
}
.btn-primary {
  background: var(--primary-color) !important;
  color: white;
  border: none;
}
.btn-primary:hover {
  background-color: #14542b !important;
}

td > small {
  color: black !important;
}

.avatar.avatar-xl {
  border-radius: 50%;
  background-color: #ececec;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.avatar.avatar-sm {
  border-radius: 50%;
  background-color: #ececec;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: small;
}

.avatar.avatar-2xl {
  border-radius: 50%;
  background-color: #ececec;
  width: 65px;
  height: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
    font-size: large;
}

.pt-8{
  padding-top: 8rem !important;
}


.pt-md-10{
  @media (min-width: 768px) {
    padding-top: 10rem !important;
  }
}

.pt-lg-12{
  @media (min-width: 992px) {
    padding-top: 12rem !important;
  }

}
